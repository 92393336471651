function Preloader () {
  return (
    <>
      <div className='loader flex flex--center'>
        <span className='loader__text'>YETI Coin</span>
      </div>
    </>
  )
}

export default Preloader
