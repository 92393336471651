function Trading (props) {
  const { setModalContent, setModalShow } = props
  return (
    <>
      <div className='content-section section--hidden' data-page='5'>
        <h1 className='content-section__title'>TRADING</h1>
        <p className='content-section__text'>
          Yeti Coin is a crypto coin based on Binance Smart Chain blockchain
          (BSC) protocol. Yeti is already listed on PancakeSwap with further
          listing on many crypto exchanges such as Binance, Coinbase, etc.
          <br /> You can follow instrictions below to add YETI Coin to your
          wallet and add liquidity to YETI Coin on Pancakeswap.
        </p>

        <div className='page-buttons'>
          <button
            className='content-section__button button button--secondary'
            onClick={() => {
              setModalContent('wallet')
              setModalShow(true)
            }}
          >
            ADDING YETI COIN INTO YOUR WALLET
          </button>
          <button
            className='content-section__button button button--secondary'
            onClick={() => {
              setModalContent('buyguide')
              setModalShow(true)
            }}
          >
            HOW TO BUY YETI
          </button>
        </div>
      </div>
    </>
  )
}

export default Trading
