function Whitepaper () {
  return (
    <>
      <div className='content-section section--hidden' data-page='6'>
        <h1 className='content-section__title'>WHITE PAPER</h1>
        <p className='content-section__text'>
          You could know more about YETI Ecosystem in our white paper
        </p>
        <a
          className='content-section__button button button--icon'
          target='_blank'
          rel='noopener noreferrer'
          href='https://yetivp.com/WhitePaper.pdf'
        >
          <span className='button__text'>OPEN WHITEPAPER IN PDF</span>
          <img
            className='button__image'
            src='./resources/images/icons/external.svg'
            alt='External icon'
          />
        </a>
      </div>
    </>
  )
}

export default Whitepaper
