import Clipboard from 'react-clipboard.js'

function Wallet () {
  return (
    <>
      <h2 className='popup__title'>ADDING YETI COIN INTO YOUR WALLET</h2>
      {/* <!-- <h2 className="popup__subtitle">Instructions for MetaMask</h2> --> */}
      <a
        className='popup__subtitle link-wrapper'
        href='https://metamask.io/'
        target='_blank'
        rel='noreferrer'
      >
        <h2 className='link__subtitle'>Instructions for MetaMask</h2>
        <img
          className='button__image'
          src='./resources/images/icons/external.svg'
          alt='External icon'
        />
      </a>
      <div className='popup__content popup__wallet'>
        <div className='steps'>
          <div className='step'>
            <h2 className='step-text'>
              1. Open Google Chrome Web Store and
              <a
                target='_blank'
                rel='noreferrer'
                href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'
              >
                {' '}
                download MetaMask{' '}
              </a>
              extension.
            </h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/wallet1.webp'
                alt='step 1 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>2. Register a MetaMask wallet</h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/wallet2.webp'
                alt='step 2 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              3. Click "Import tokens" and import YETI Coin into MetaMask wallet
            </h2>
            <h3 className='step-text'>
              YETI Coin contract address:{' '}
              <small>0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9</small>{' '}
              <Clipboard
                className='copy button button--small'
                data-clipboard-text='0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
                button-title='copy to clipboard'
              >
                Copy Address
              </Clipboard>
            </h3>
            <div className='step-image iv'>
              <img
                src='/resources/images/instructions/wallet3.webp'
                alt='step 3 screen'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Wallet
