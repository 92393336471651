import Clipboard from 'react-clipboard.js'

function Liquidity (props) {
  return (
    <>
      <h2 className='popup__title'>ADDING LIQUIDITY TO YETI COIN</h2>
      <a
        className='popup__subtitle link-wrapper'
        href='https://pancakeswap.finance/'
        target='_blank'
        rel='noreferrer'
      >
        <h2 className='link__subtitle'>Instructions for PancakeSwap</h2>
        <img
          className='button__image'
          src='./resources/images/icons/external.svg'
          alt='External icon'
        />
      </a>
      <div className='popup__content popup__liquid'>
        <div className='steps'>
          <div className='step'>
            <h2 className='step-text'>
              1. Go to
              <a
                target='_blank'
                rel='noreferrer'
                href='https://pancakeswap.finance/'
              >
                {' '}
                PancakeSwap{' '}
              </a>
              and click "Connect Wallet"
            </h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/pancake1.webp'
                alt='step 1 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              2. Connect MetaMask wallet to PancakeSwap.
            </h2>
            <div className='step-image iv'>
              <img
                src='/resources/images/instructions/pancake2.webp'
                alt='step 2 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>3. Import YETI Coin to PancakeSwap</h2>
            <h3 className='step-text'>
              Click to Select a currency - Manage Tokens and enter Yeti Coin
              Contract Address. Then import.
            </h3>
            <h3 className='step-text'>
              YETI Coin contract address:{' '}
              <small>0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9</small>{' '}
              <Clipboard
                className='copy button button--small'
                data-clipboard-text='0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
                button-title='copy to clipboard'
              >
                Copy Address
              </Clipboard>
            </h3>
            <div className='step-images'>
              <div className='step-image ih'>
                <img
                  src='/resources/images/instructions/pancake3.webp'
                  alt='step 3 screen'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/pancake4.webp'
                  alt='step 3 screen 2'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/pancake4_3.webp'
                  alt='step 3 screen 3'
                />
              </div>
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>4. Exchange BNB to YETI Coin</h2>
            <div className='step-image iv'>
              <img
                src='/resources/images/instructions/pancake5.webp'
                alt='step 4 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              5. To be able to add liquidity, you need to have YETi Coin on your
              balance in the same amount that you would like to add liquidity.
              Today we have a bunch of BNB/Yeti. So you will need BNB to add
              liquidity to Yeti Coin
            </h2>
            <div className='step-image iv'>
              <img
                src='/resources/images/instructions/pancake6.webp'
                alt='step 5 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              6. Go to Liquidity and then + Add Liquidity
            </h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/pancake7.webp'
                alt='step 7 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              7. Select BNB and Yeti Coin. Set the value
            </h2>
            <div className='step-images'>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/pancake8.webp'
                  alt='step 6 screen'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/pancake9.webp'
                  alt='step 6 screen 2'
                />
              </div>
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>8. Supply! That's all!</h2>
            <h3 className='step-text'>
              The commission for adding liquidity will be returned to your
              wallet in equal proportion to the amount of adding liquidity. The
              commission will be from PancakeSwap in LP.
            </h3>
            <div className='step-images'>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/pancake10.webp'
                  alt='step 7 screen'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Liquidity
