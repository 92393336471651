import pages from '../pagesList'

function Header (props) {
  const {
    showMenu,
    setShowMenu,
    currentPageIndex,
    setCurrentPageIndex,
    setModalContent,
    setModalShow
  } = props

  return (
    <>
      <header className='fixed-content-header'>
        <div className='flex flex--center'>
          <BurgerButton showMenu={showMenu} setShowMenu={setShowMenu} />
          <div className='fixed-content-header__logo'>YETI</div>
        </div>
        <MenuLinks
          pages={pages}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
        />
        <div className='header-buttons'>
          {/* <button
            className='button button--third button-presale'
            data-follow
            onClick={() => {
              setModalContent('buy')
              setModalShow(true)
            }}
          >
            PRESALE
          </button> */}
          <button
            className='button button--third'
            data-follow
            onClick={() => {
              setModalContent('buy')
              setModalShow(true)
            }}
          >
            BUY
          </button>
          <button
            className='fixed-content-header__contact button button--third'
            data-follow
            onClick={() => {
              const index = pages.findIndex(page => page.id === 'contact')
              setCurrentPageIndex(index)
            }}
          >
            CONTACT
          </button>
        </div>
      </header>
    </>
  )
}

function BurgerButton (props) {
  const { showMenu, setShowMenu } = props
  return (
    <div
      className={`burger ${showMenu ? 'burger--active' : ''}`}
      data-follow
      onClick={() => {
        setShowMenu(!showMenu)
      }}
    >
      <div className='burger__line' />
      <div className='burger__line' />
      <div className='burger__line' />
    </div>
  )
}

function MenuLinks (props) {
  const { pages, currentPageIndex, setCurrentPageIndex } = props

  const menuItems = pages.map((page, i) => {
    const { id, title } = page
    if (id === 'main') return null
    return (
      <MenuItem
        key={`header-menu-${i}`}
        pageIndex={i}
        text={title}
        onClick={() => {
          console.log(`header-menu-${i}`)
          setCurrentPageIndex(i)
        }}
        currentPageIndex={currentPageIndex}
      />
    )
  })
  return (
    <div className='fixed-content-header__menu'>
      <ul className='fixed-content-header__menu-list'>{menuItems}</ul>
    </div>
  )
}

function MenuItem (props) {
  const { text, pageIndex, onClick, currentPageIndex } = props
  return (
    <li
      className={`header-menu-list__item ${
        currentPageIndex === pageIndex ? '' : 'section--hidden'
      }`}
      onClick={onClick}
      data-page={pageIndex}
    >
      {text}
    </li>
  )
}

export default Header
