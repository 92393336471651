import Clipboard from 'react-clipboard.js'

function BuyGuide (props) {
  return (
    <>
      <h2 className='popup__title'>HOW TO BUY YETI</h2>
      <a
        className='popup__subtitle link-wrapper'
        href='https://pancakeswap.finance/'
        target='_blank'
        rel='noreferrer'
      >
        <h2 className='link__subtitle'>Instructions for PancakeSwap</h2>
        <img
          className='button__image'
          src='./resources/images/icons/external.svg'
          alt='External icon'
        />
      </a>
      <div className='popup__content popup__liquid'>
        <div className='steps'>
        <div className='step'>
            <h2 className='step-text'>
              1. Open Google Chrome Web Store and
              <a
                target='_blank'
                rel='noreferrer'
                href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'
              >
                {' '}
                download MetaMask{' '}
              </a>
              extension.
            </h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/wallet1.webp'
                alt='step 1 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>2. Register a MetaMask wallet</h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/wallet2.webp'
                alt='step 2 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              3. Go to
              <a
                target='_blank'
                rel='noreferrer'
                href='https://pancakeswap.finance/'
              >
                {' '}
                PancakeSwap{' '}
              </a>
              and connect MetaMask wallet
            </h2>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/buy/i3.webp'
                alt='step 1 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>
              4. Then click on token - manage tokens - paste the contract address and click “import token”
            </h2>
            <h3 className='step-text'>
              YETI Coin contract address:{' '}
              <small>0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9</small>{' '}
              <Clipboard
                className='copy button button--small'
                data-clipboard-text='0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
                button-title='copy to clipboard'
              >
                Copy Address
              </Clipboard>
            </h3>
            <div className='step-images'>
              <div className='step-image ih'>
                <img
                  src='/resources/images/instructions/buy/i4.webp'
                  alt='step 4 screen'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/buy/i4.1.webp'
                  alt='step 4 screen 2'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/buy/i4.2.webp'
                  alt='step 4 screen 3'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/buy/i4.3.webp'
                  alt='step 4 screen 4'
                />
              </div>
              <div className='step-image iv'>
                <img
                  src='/resources/images/instructions/buy/i4.4.webp'
                  alt='step 4 screen 5'
                />
              </div>
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>5. At the moment there is only one pair - BNB/YETI</h2>
            <h3 className='step-text'>
            To buy YETI Coin you have to exchange it for BNB:
            - enter the BNB amount
            - Click Swap
            </h3>
            <div className='step-image ih'>
              <img
                src='/resources/images/instructions/buy/i5.webp'
                alt='step 5 screen'
              />
            </div>
          </div>
          <div className='step'>
            <h2 className='step-text'>6. After confirming the operation, the YETI will appear in the MetaMask wallet</h2>
            <h3 className='step-text'>
            To see it in your wallet:
            Click "Import tokens" and import YETI Coin into MetaMask wallet
            </h3>
            <h3 className='step-text'>
              YETI Coin contract address:{' '}
              <small>0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9</small>{' '}
              <Clipboard
                className='copy button button--small'
                data-clipboard-text='0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
                button-title='copy to clipboard'
              >
                Copy Address
              </Clipboard>
            </h3>
            <div className='step-image iv'>
              <img
                src='/resources/images/instructions/buy/i6.webp'
                alt='step 6 screen'
              />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default BuyGuide
