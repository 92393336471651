import React, { useEffect, useState, useRef } from 'react'
import Icon from 'react-crypto-icons'
import FuzzySearch from 'fuzzy-search'
import { css } from '@emotion/react'
import { RingLoader } from 'react-spinners'
import OutsideClickHandler from 'react-outside-click-handler'

// import { mapLimit, asyncRoot, sleep } from 'modern-async'

import {
  useEthers,
  useToken,
  useTokenBalance,
  useEtherBalance,
  // useGasPrice
} from '@usedapp/core'
import { formatEther, formatUnits, parseEther} from '@ethersproject/units'

import {
  getExchangeRates,
  getEstimateUSD,
  getEstimateCrypto,
  getCurrencyInfo,
  createPayment
} from './requests'

import warningImg from '../../warning.svg'
import logo from '../../yeti_icon.min.svg'
import dropdownArrow from '../../dropdown-arrow.svg'
import star from '../../SimpleStar.svg'

const YetiContract = '0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
const sendAddress = '0xf8394C2Ad7D009170dBb24C591193e0712F287b7'
const featuredCid = 'bnbbsc'

export function Swap (props) {
  const {
    setCloseOnOverlayClick,
    currenciesDetails,
    walletAddress,
    setOrderInfo,
    cryptoSymbols
  } = props

  const presaleLimit = useTokenBalance(YetiContract, sendAddress)
  // const gasPrice = useGasPrice()
  // const ethers = useEthers()
  // const gasPriceF = (gasPrice && formatUnits(gasPrice, 18)) || '0'
  // console.log('gasPriceF', gasPriceF)
  // const { account, chainId, library, error } = ethers
  // errors
  const [showErrors, setShowErrors] = useState(false)
  const [fromError, setFromError] = useState('')
  const [toError, setToError] = useState('')
  // loaders
  const [showToLoader, setShowToLoader] = useState(false)
  const [showFromLoader, setShowFromLoader] = useState(false)
  const [showCreateOrderLoader, setShowCreateOrderLoader] = useState(false)
  // YETI/USD and current crypto/USD exchange rates
  const [exchangeRates, setExchangeRates] = useState(null)
  const [exRatePair, setExRatePair] = useState(null)
  // minimums
  const [minimum, setMinimum] = useState(0.02)
  const [minimumYETI, setMinimumYETI] = useState(250)
  // values
  const [fromCID, setFromCID] = useState('bnbbsc')
  const [fromCoins, setFromCoins] = useState('')
  const [fromSymbol, setFromSymbol] = useState('bnb')
  const [toCoins, setToCoins] = useState('')
  const [toCurrency, setToCurrency] = useState('YETI')
  // other
  const [estimateDirection, setEstimateDirection] = useState(null)
  const [showSearch, setShowSearch] = useState(false)



  // prevent close modal on outside click if search open
  useEffect(() => {
    let to
    if (showSearch) {
      setCloseOnOverlayClick(false)
    } else {
      to = setTimeout(() => {
        setCloseOnOverlayClick(true)
      }, 0)
      return () => {
        clearTimeout(to)
        setCloseOnOverlayClick(true)
      }
    }
    return () => {
      clearTimeout(to)
    }
  }, [showSearch, setCloseOnOverlayClick])

  // get YETI to UST exchange rate and minimum amount
  useEffect(() => {
    const run = async () => {
      const res = await getExchangeRates()
      console.log('getExchangeRates', res.success, res.rates, res.minimum)
      if (res.success) {
        setExchangeRates(res.rates)
        setMinimumYETI(res.minimum)
      }
    }
    run()
  }, [fromCID])

  // getCurrencyInfo and setMinimum amount
  useEffect(() => {
    const run = async () => {
      const res = await getCurrencyInfo(fromCID)
      console.log('getCurrencyInfo', fromCID, res.success, res.minimum)

      if (res.success && res.available) {
        setMinimum(res.minimum)
      }
    }
    run()
  }, [fromCID])

  // getEstimateUSD (get YETI amount by crypto input value)
  useEffect(() => {
    if (estimateDirection === 'YETI') return
    const amount = +fromCoins
    if (!amount) return
    if (!exchangeRates.usd) return

    const run = async () => {
      const res = await getEstimateUSD(fromCID, amount)
      console.log(
        'getEstimateUSD',
        estimateDirection,
        fromCID,
        res.success,
        res.estimate
      )

      if (res.success) {
        // const yetiAmount = res.estimate / exchangeRates.usd
        const yetiAmount = +parseFloat(
          res.estimate / exchangeRates.usd
        ).toFixed(9)
        setShowToLoader(false)
        setToCoins(yetiAmount)
        setExRatePair(yetiAmount / amount)
      }

      // rechecks
    }

    const to = setTimeout(() => {
      console.log('getEstimateUSD tick')
      setShowToLoader(true)
      run()
    }, 400)
    setShowToLoader(true)
    console.log('getEstimateUSD set 300')

    const recheckInterval = setInterval(() => {
      console.log('getEstimateUSD recheckInterval tick')
      setShowToLoader(true)
      run()
    }, 10000)
    console.log('getEstimateUSD set recheckInterval 10000')

    return () => {
      console.log('getEstimateUSD clearTimeout 300')
      clearTimeout(to)
      console.log('getEstimateUSD clearInterval 10000')
      clearInterval(recheckInterval)
    }
  }, [fromCoins, fromCID, exchangeRates, estimateDirection])

  // getEstimateCrypto (get crypto amount by YETI input value)
  useEffect(() => {
    if (estimateDirection === 'NOW') return
    const amount = +toCoins
    if (!amount) return
    if (!exchangeRates.usd) return

    const run = async () => {
      const res = await getEstimateCrypto(fromCID, amount * exchangeRates.usd)
      console.log(
        'getEstimateCrypto',
        estimateDirection,
        fromCID,
        res.success,
        res.estimate
      )

      if (res.success) {
        const coinAmount = res.estimate
        setShowFromLoader(false)
        setFromCoins(coinAmount)
        setExRatePair(amount / coinAmount)
      }
    }

    const to = setTimeout(() => {
      setShowFromLoader(true)
      run()
    }, 300)

    const recheckInterval = setInterval(() => {
      setShowFromLoader(true)
      run()
    }, 10000)

    return () => {
      clearTimeout(to)
      clearInterval(recheckInterval)
    }
  }, [toCoins, fromCID, exchangeRates, estimateDirection])

  // check Minimum amounts
  useEffect(() => {
    if (!+fromCoins) return

    if (+fromCoins < minimum) {
      setFromError(`Minimum ${fromSymbol.toUpperCase()} amount is ${minimum}`)
      setShowErrors(true)
    } else if (+fromCoins > minimum) {
      setFromError('')
    }
  }, [fromCoins, fromSymbol, minimum])

  // check minimum and maximum YETI amount
  useEffect(() => {
    const coins = +toCoins
    if (!coins) return

    const maxYeti = (presaleLimit && formatUnits(presaleLimit, 9)) || '0'
    if (coins < minimumYETI) {
      setToError(`Minimum ${toCurrency.toUpperCase()} amount is ${minimumYETI}`)
      setShowErrors(true)
    } else if (coins > +maxYeti) {
      const maxYetiFormat = (+maxYeti).toFixed(4)
      setToError(
        `Only ${maxYetiFormat} ${toCurrency.toUpperCase()} tokens available!`
      )
      setShowErrors(true)
    } else if (coins < +maxYeti) {
      setToError('')
    }
  }, [toCoins, toCurrency, minimumYETI, presaleLimit])

  const payProps = {
    fromCoins,
    setFromCoins,
    setToCoins,
    fromSymbol,
    setFromSymbol,
    fromError,
    setFromError,
    setShowSearch,
    showErrors,
    setShowErrors,
    setEstimateDirection,
    showFromLoader
  }

  const receiveCurrencyProps = {
    toCoins,
    setToCoins,
    toCurrency,
    setToCurrency,
    showErrors,
    toError,
    setToError,
    setShowErrors,
    setEstimateDirection,
    showToLoader
  }

  const searchProps = {
    showSearch,
    setShowSearch,
    currenciesDetails,
    setFromSymbol,
    setFromCID,
    cryptoSymbols
  }

  const createPaymentProps = {
    fromCID,
    toCoins,
    setToCoins,
    fromCoins,
    setFromCoins,
    walletAddress,
    exchangeRates,
    setOrderInfo,
    fromError,
    toError,
    showToLoader,
    showFromLoader,
    showCreateOrderLoader,
    setShowCreateOrderLoader,
    estimateDirection
  }

  const exRateProps = { exRatePair, toCurrency, fromSymbol }

  if (!props) return null

  return (
    <>
      <div>
        <PayCurrency {...payProps} />
        <ReceiveCurrency {...receiveCurrencyProps} />
        <ExchangeRateInfo {...exRateProps} />
      </div>
      <div>
        <CreatePaymentButton {...createPaymentProps} />

        <SearchCurrency {...searchProps} />
      </div>
    </>
  )
}

export function ExchangeRateInfo (props) {
  const { exRatePair, toCurrency, fromSymbol } = props
  if (!exRatePair) return null
  const normalizedRate = +parseFloat(exRatePair).toFixed(9)

  return (
    <div className='white center'>
      {`* 1 ${fromSymbol.toUpperCase()} ~ ${normalizedRate} ${toCurrency}`}
    </div>
  )
}

export function SearchCurrency (props) {
  const {
    showSearch,
    setShowSearch,
    currenciesDetails,
    setFromSymbol,
    setFromCID,
    cryptoSymbols
  } = props

  const searchInput = useRef(null)
  const [input, setInput] = useState('')
  const [filtered, setFiltered] = useState(currenciesDetails)

  useEffect(() => {
    const searcher = new FuzzySearch(
      currenciesDetails,
      ['symbol', 'name', 'chain'],
      {
        caseSensitive: false
      }
    )
    const result = searcher.search(input)
    setFiltered(result)
  }, [currenciesDetails, input])

  useEffect(() => {
    if (!showSearch) {
      setInput('')
    } else if (showSearch && searchInput.current) {
      console.log('searchInput.current.focus()')
      searchInput.current.focus()
    }
  }, [showSearch])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSearch(false)
      }}
    >
      <div
        className={`currencySearch ${showSearch ? '' : 'hidden'}`}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <div className='yeti-input__wrapper'>
          <div className='yeti-input_line yeti-search'>
            <input
              ref={searchInput}
              autoComplete='off'
              className='yeti-input center'
              type='text'
              name='coins'
              placeholder='Currency Symbol or Name'
              value={input}
              onChange={event => {
                setInput(event.target.value)
                // const result = searcher.search(event.target.value)
                // setFiltered(result)
                // console.log('input', event.target.value, 'result', result)
              }}
            />
          </div>
        </div>
        <div className='searchFilter'>
          <AvailableCurrencies
            currenciesDetails={filtered}
            setFromSymbol={setFromSymbol}
            setShowSearch={setShowSearch}
            setFromCID={setFromCID}
            cryptoSymbols={cryptoSymbols}
          />
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export function AvailableCurrencies (props) {
  const {
    currenciesDetails,
    setFromSymbol,
    setShowSearch,
    setFromCID,
    cryptoSymbols
  } = props

  const availableCurrencies = currenciesDetails.map(info => {
    const { cid, symbol, chain } = info
    const isFeatured = cid === featuredCid

    const title = `${symbol.toUpperCase()} ${
      chain ? '(' + chain.toUpperCase() + ')' : ''
    } - ${cryptoSymbols[symbol.toUpperCase()]}`

    return (
      <div
        key={`crypto-${cid}`}
        className='currency currency-filter row'
        onClick={() => {
          setFromSymbol(symbol)
          setFromCID(cid)
          setShowSearch(false)
        }}
      >
        <div className='crypto-info row'>
          <div className='cryptoicon'>
            <Icon name={symbol} size={32} />
          </div>
          <div className='cryptoname'>{title}</div>
        </div>
        {isFeatured ? (
          <img alt='featured' src={star} className='featured-cid' />
        ) : null}
      </div>
    )
  })

  return <div className='currencies'>{availableCurrencies}</div>
}

export function PayCurrency (props) {
  const {
    fromCoins,
    setFromCoins,
    setToCoins,
    fromSymbol,
    setFromSymbol,
    fromError,
    setFromError,
    setShowSearch,
    showErrors,
    setShowErrors,
    setEstimateDirection,
    showFromLoader
  } = props

  const ethers = useEthers()
  const { account } = ethers
  // const yetiInfo = useToken(YetiContract)
  // const yetiBalance = useTokenBalance(YetiContract, account)
  // const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||
  const etherBalance = useEtherBalance(account)
  const etherBalanceFormated = etherBalance ? formatEther(etherBalance) : 0

  return (
    <div className='yeti-input__wrapper'>
      <PayBalance fromSymbol={fromSymbol} balance={etherBalanceFormated} />
      <div
        className={`yeti-input_line ${showErrors && fromError ? 'error' : ''}`}
      >
        <input
          autoComplete='off'
          className={`yeti-input ${showFromLoader ? 'updating' : ''}`}
          type='text'
          name='coins'
          placeholder='Selected Coin Amount' // {`${fromSymbol.toUpperCase()} Amount`}
          value={fromCoins}
          onChange={event => {
            const value = +event.target.value
            const float = parseFloat(event.target.value)
            console.log('value', !!value, float, value)

            if (!value) {
              setFromError('Enter correct coins number!')
              setShowErrors(true)
            } else {
              setFromError('')
              setShowErrors(false)
            }
            setEstimateDirection('NOW')
            setFromCoins(event.target.value)
          }}
        />
        <div className={`updatingLoader ${showFromLoader ? '' : 'hidden'}`}>
          <RingLoader
            color='#ffffff'
            loading
            css={css`
              margin: 0 auto;
            `}
            size={32}
          />
        </div>
        <div
          className='currency pointer'
          onClick={() => {
            setShowSearch(true)
          }}
        >
          <div className='cryptoicon'>
            <Icon name={fromSymbol} size={32} />
          </div>
          <div className='cryptoname'>{fromSymbol.toUpperCase()}</div>
          <div className='dropdown-arrow'>
            <img src={dropdownArrow} alt='select' />
          </div>
        </div>
      </div>{' '}
      <p className={'input-error ' + (showErrors && fromError ? '' : 'hidden')}>
        <img className='mr-1' src={warningImg} alt='warning' />
        <strong className='self-center'>{fromError}</strong>
      </p>
    </div>
  )
}

function PayBalance (props) {
  const { fromSymbol, balance } = props

  return (
    <div className='balance row white'>
      <div className='direction-info'>You Pay</div>
      <div className='balance-info'>
        Balance: {+(+balance).toFixed(4)} {fromSymbol.toUpperCase()}
      </div>
    </div>
  )
}

export function ReceiveCurrency (props) {
  const {
    toCoins,
    setToCoins,
    toCurrency,
    setToCurrency,
    toError,
    setToError,
    showErrors,
    setShowErrors,
    setEstimateDirection,
    showToLoader
  } = props

  return (
    <div className='yeti-input__wrapper'>
      <ReceiveBalance toCurrency={toCurrency} />
      <div
        className={`yeti-input_line ${showErrors && toError ? 'error' : ''}`}
      >
        <input
          autoComplete='off'
          className={`yeti-input ${showToLoader ? 'updating' : ''}`}
          type='text'
          name='coins'
          placeholder='YETI Amount'
          value={toCoins}
          onChange={event => {
            const value = +event.target.value
            if (!value) {
              setToError('Enter correct coins number!')
              setShowErrors(true)
            } else {
              setToError('')
              setShowErrors(false)
            }
            setEstimateDirection('YETI')
            setToCoins(event.target.value)
          }}
        />
        <div className={`updatingLoader ${showToLoader ? '' : 'hidden'}`}>
          <RingLoader
            color='#ffffff'
            loading
            css={css`
              margin: 0 auto;
            `}
            size={32}
          />
        </div>
        <div className='currency'>
          <div className='cryptoicon'>
            <img className='yetiicon' src={logo} alt='YETI Coin Icon' />
          </div>
          <div className='cryptoname'>YETI</div>
        </div>
      </div>{' '}
      <p className={'input-error ' + (showErrors && toError ? '' : 'hidden')}>
        <img className='mr-1' src={warningImg} alt='warning' />
        <strong className='self-center'>{toError}</strong>
      </p>
    </div>
  )
}

function ReceiveBalance (props) {
  const { toCurrency } = props
  const ethers = useEthers()
  const { account } = ethers
  const yetiInfo = useToken(YetiContract)
  const yetiBalance = useTokenBalance(YetiContract, account)
  const yetiBalanceFormated = yetiBalance ? formatUnits(yetiBalance, 9) : 0 // daiInfo?.decimals ||

  return (
    <div className='balance row white'>
      <div className='direction-info'>You Receive</div>
      <div className='balance-info'>
        Balance: {+(+yetiBalanceFormated).toFixed(4)} {toCurrency.toUpperCase()}
      </div>
    </div>
  )
}

export function CreatePaymentButton (props) {
  const {
    fromCID,
    toCoins,
    setToCoins,
    fromCoins,
    setFromCoins,
    walletAddress,
    exchangeRates,
    setOrderInfo,
    fromError,
    toError,
    showToLoader,
    showFromLoader,
    showCreateOrderLoader,
    setShowCreateOrderLoader,
    estimateDirection
  } = props

  const hasAllParams = !!(
    fromCID &&
    toCoins &&
    fromCoins &&
    walletAddress &&
    exchangeRates
  )
  const notLoading = !showToLoader && !showFromLoader && !showCreateOrderLoader
  const valid = !!(hasAllParams && !fromError && !toError && notLoading)

  return (
    <button
      className={`button button--third metamask-buttons create-order ${
        valid ? 'create-order-active' : 'create-order-inactive'
      }`}
      onClick={async () => {
        console.log(
          `CreatePaymentButton is valid ${valid} notLoading ${notLoading} hasAllParams ${hasAllParams}`
        )
        if (!valid) return
        setShowCreateOrderLoader(true)
        const res = await createPayment(props)
        console.log('createPayment res', res)
        setShowCreateOrderLoader(false)
        if (res && res.success) {
          setOrderInfo(res.payment)
          setToCoins('')
          setFromCoins('')
        }
      }}
    >
      {showCreateOrderLoader ? 'SENDING ORDER' : 'CREATE ORDER'}
    </button>
  )
}

export default Swap
