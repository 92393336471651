function BuyPancake (props) {
  return (
    <>
      <h2 className='popup__title'>BUY YETI Coin</h2>
      <a
        className='popup__subtitle link-wrapper'
        href='
          https://poocoin.app/swap?outputCurrency=0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
        target='_blank'
        rel='noreferrer'
      >
        <h2 className='link__subtitle'>Open in poocoin.app</h2>
        <img
          className='button__image'
          src='./resources/images/icons/external.svg'
          alt='External icon'
        />
      </a>
      <div className='popup__content popup__roadmap'>
        <iframe
          title='poocoin'
          className='popup__content'
          src='https://poocoin.app/embed-swap?outputCurrency=0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
          width='420'
          height='630'
        />
      </div>
    </>
  )
}

export default BuyPancake
