function Wallets () {
  return (
    <>
      <div className='content-section section--hidden' data-page='4'>
        <h1 className='content-section__title'>WALLETS</h1>
        <p className='content-section__text'>
          You could use any BSC (Binance Smart Chain) compatible wallet for
          holding YETI Coin.
          <br />
          If you don't know any use one of recommended below:
        </p>
        <div className='wallets-list content-section__wrapper'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://metamask.io/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/MetaMask.jpg'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>MetaMask</h3>
                <p className='wallet-desc'>
                  A crypto wallet &amp; gateway to blockchain apps
                </p>
              </div>
            </div>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://trustwallet.com/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/trust_platform.png'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>Trust Wallet</h3>
                <p className='wallet-desc'>
                  The most trusted & secure crypto wallet
                </p>
              </div>
            </div>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://mathwallet.org/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/MathWallet_App_Icon.png'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>Math Wallet</h3>
                <p className='wallet-desc'>
                  Your Gateway to the World of Blockchain
                </p>
              </div>
            </div>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.tokenpocket.pro/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/tokenpocket.png'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>TokenPocket</h3>
                <p className='wallet-desc'>
                  Multi-chain, security and convenience, the portal of DApp
                </p>
              </div>
            </div>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.safepal.io/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/SafePal.png'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>SafePal</h3>
                <p className='wallet-desc'>
                  Always secure and protected, wherever you go
                </p>
              </div>
            </div>
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.venly.io/'
            className='wallet-card content-section__item'
          >
            <div className='wallet-card-w'>
              <div className='wallet-thumbnail-w'>
                <img
                  alt='MetaMask thumbnail'
                  src='/resources/images/wallets/venly.png'
                  className='wallet-thumbnail'
                />
              </div>
              <div className='wallet-info'>
                <h3 className='wallet-title'>Venly</h3>
                <p className='wallet-desc'>
                  Wallet & NFT products that seamlessly integrate with your
                  blockchain project
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default Wallets
