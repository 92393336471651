import React, { useState } from 'react'
import Modal from 'react-modal'

import Buy from './Buy/'
import BuyPancake from './BuyPancake'
import Liquidity from './Liquidity'
import BuyGuide from './BuyGuide'
import Roadmap from './Roadmap'
import Wallet from './Wallet'

const modalStyles = {
  overlay: {
    backgroundColor: '#000000db',
    zIndex: 2
  },
  content: {
    // height: '80vh',
    // width: '80vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '0',
    overflow: 'none',
    padding: 'none',
    background: '#00000066',
    zIndex: 3
  }
}

Modal.setAppElement('#root')

function ModalPopup (props) {
  const { modalShow, setModalShow, modalContent } = props
  const [animated, setAnimated] = useState(false)
  const [closeOnOverlayClick, setCloseOnOverlayClick] = useState(true)
  let content = null
  let additionalClass = 'ReactModalPortal'

  switch (modalContent) {
    // case 'buy':
    //   content = <Buy setCloseOnOverlayClick={setCloseOnOverlayClick} />
    //   additionalClass = 'ReactModalPortal modal-presale'
    //   break
    case 'buy':
    case 'buypancake':
      content = <BuyPancake />
      break
    case 'roadmap':
      content = <Roadmap />
      break
    case 'buyguide':
        content = <BuyGuide />
        break
    case 'liquidity':
      content = <Liquidity />
      break
    case 'wallet':
      content = <Wallet />
      break
    default:
      return null
  }

  return (
    <Modal
      portalClassName={additionalClass}
      isOpen={modalShow}
      onAfterOpen={() => {
        setAnimated(true)
      }}
      onRequestClose={() => {
        setAnimated(false)
        setModalShow(false)
      }}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      style={modalStyles}
      contentLabel='Modal'
    >
      <div className='custom-scroll wrapper'>
        <div className={`burger-wrapper ${animated ? ' popup--animated' : ''}`}>
          <div
            className='burger burger--active'
            onClick={() => {
              setAnimated(false)
              setModalShow(false)
              setCloseOnOverlayClick(true)
            }}
            data-follow
          >
            <div className='burger__line' />
            <div className='burger__line' />
          </div>
        </div>

        <div
          className={`zpopup ${modalShow ? ' popup--active' : ''} ${
            animated ? ' popup--animated' : ''
          }`}
        >
          <div className='popup__inner'>{content}</div>
        </div>
        {/* <button
        className='modalClose focus:outline-none'
        onClick={() => setModalShow(false)}
      >
        <div className='burger burger--active'>
          <div className='burger__line' />
          <div className='burger__line' />
        </div>
      </button>
      <div className='text-center flex flex-col max-w-md'>
         */}
        {/* <Routes location={location}>
      <Route
        path='/processing'
        element={<Processing token={token} setAccount={setAccount} />}
      />
      <Route
        path='/activated'
        element={<SubscriptionActivated account={account} />}
      />
      <Route
        path='/cancel'
        element={
          <CancelSub
            token={token}
            account={account}
            onConfirm={() => setModalShow(false)}
            onCancel={() => setModalShow(false)}
          />
        }
      />
    </Routes> */}
        {/* </div> */}
      </div>
    </Modal>
  )
}

export default ModalPopup
