function Roadmap () {
  return (
    <>
      <h2 className='popup__title'>ROAD MAP</h2>
      <h2 className='popup__subtitle'>
        Some of the things we did or plan to do
      </h2>
      <div className='popup__content popup__roadmap'>
        <div className='year-plans'>
          <h2 className='year-plans-year'>2021</h2>
          <div className='plans-wrapper'>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>DONE</h3>
              <div className='popup__rdm-desc'>
                In 2020,{' '}
                <a target='_blank' href='https://yetivp.com' rel='noreferrer'>
                  {' '}
                  Yeti VPN{' '}
                </a>{' '}
                utility was developed from scratch for IOS and Android devices.
                The app already has a large user base - 300,000 downloads and
                200,000 active users! Yeti VPN provides you a secure, encrypted
                tunnel for online traffic to flow. Nobody can see through the
                tunnel and get their hands on your internet data. Yeti VPN is
                the best choice if you’re looking for peace of mind when you use
                public Wi-Fi, access personal and work accounts on the road, or
                want to keep your browsing history to yourself.
              </div>
            </div>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>December</h3>
              <div className='popup__rdm-desc'>Listing🤫 Stay tuned.</div>
            </div>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>December</h3>
              <div className='popup__rdm-desc'>
                NFT. In addition to Internet security, we also plan to issue
                non-transferable tokens (NFTs) to celebrities worldwide with
                payment for YETI Coin on the Open Sea Marketplace. The first
                NFTs will be released in December 2021.
              </div>
            </div>
          </div>
        </div>

        <div className='year-plans'>
          <h2 className='year-plans-year'>2022</h2>
          <div className='plans-wrapper'>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>March</h3>
              <div className='popup__rdm-desc'>
                Yeti Messenger. The most secure free messenger for all users
                worldwide, will be launched in March 2022. Coin holders will
                have an exclusive opportunity to be among the first users to use
                the messenger.
              </div>
            </div>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>May</h3>
              <div className='popup__rdm-desc'>
                Yeti Video Plugin. The video plugin with background swap for
                front-facing cameras will be released to enhance data protection
                for every Yeti VPN user and YETI Coin holder. The main advantage
                over other plugins is a virtual camera swap based on an
                artificial neural network with 100% video stream protection.
                Yeti Plugin will be available for sale for YETI Coin.
              </div>
            </div>

            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>Spring</h3>
              <div className='popup__rdm-desc'>
                Yeti Ecosystem Telegram Channel. Yeti VPN users and Yeti Coin
                holders will have a free subscription to a Telegram channel with
                crypto trading analytics and order signals! We are developing a
                bot that analyzes charts and all possible crypto exchanges to
                output recommendations to the Yeti Ecosystem telegram channel.
              </div>
            </div>
            <div className='popup__rdm'>
              <h3 className='popup__rdm-title'>Summer</h3>
              <div className='popup__rdm-desc'>
                Yeti Games. We haven't forgotten about gamers and eSports fans,
                we're developing a game, where it will be possible to mine Yeti
                Coin (and pay for all Yeti Ecosystem products with mined coins),
                and buy NFT of rare skins, weapons, collectible cards, etc. The
                game will be available to users around the world in the summer
                of 2022.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Roadmap
