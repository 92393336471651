import React, { useEffect, useState } from 'react'
import { cryptoSymbol } from 'crypto-symbol'
// import _ from 'lodash'
import { mapLimit } from 'modern-async'
// import { RingLoader } from 'react-spinners'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import OrdersHistory from './History'
import DApp from './Dapp'

import Swap from './Swap'
import { getCurrencies, getOrdersList, getPaymentStatus } from './requests'

import '../../styles/presale.css'

// const YetiContract = '0x1DaA04B4D70533Af4B96190bd8E7d4913F2220d9'
const { get: getSymbol } = cryptoSymbol({}) // nameLookup, symbolLookup
const cryptoSymbols = getSymbol().SNPair

// const POLLING_INTERVAL = 12000
const RPC_URLS = {
  1: 'https://mainnet.infura.io/v3/10dc06d1ed0446a7aabb9dc86df0983d',
  56: 'https://bsc-dataseed.binance.org/'
}

const incompleteStatuses = [
  'waiting',
  'confirming',
  'confirmed',
  'sending',
  'partially_paid'
]

const walletConnect = new WalletConnectConnector({
  chainId: 56,
  rpc: { 1: RPC_URLS[1], 56: RPC_URLS[56] },
  bridge: 'https://bridge.walletconnect.org',
  // pollingInterval: POLLING_INTERVAL,
  qrcode: true
})

function BuyPresale (props) {
  const { setCloseOnOverlayClick } = props

  const [currencies, setCurrencies] = useState([])
  const [currenciesDetails, setCurrenciesDetails] = useState([])

  const [hideButtons, setHideButtons] = useState(false)
  const [walletAddress, setWalletAddress] = useState(null)
  const [allowAutoHide, setAllowAutoHide] = useState(true)
  const [orderInfo, setOrderInfo] = useState(null)
  const [ordersList, setOrdersList] = useState([])
  const [ordersListStatuses, setOrdersListStatuses] = useState([])
  const [incompleteOrdersList, setIncompleteOrdersList] = useState([])

  // Autohide MetaMask controls
  useEffect(() => {
    if (walletAddress && allowAutoHide) {
      setHideButtons(true)
    }
  }, [walletAddress, allowAutoHide])

  // get availableCurrencies
  useEffect(() => {
    const run = async () => {
      const res = await getCurrencies()
      // console.log('getCurrencies', res.success, res.currencies)
      if (res.success) {
        setCurrencies(res.currencies)
      }
    }
    run()

    const reCheck = setInterval(() => {
      run()
    }, 30000)

    return () => {
      clearInterval(reCheck)
    }
  }, [])

  // setCurrenciesDetails
  useEffect(() => {
    const availableCurrencies = currencies.sort().map(cid => {
      let symbol = cid
      let chain = ''

      if (cid.substr(-3) === 'bsc') {
        symbol = cid.substr(0, cid.length - 3)
        chain = 'bsc'
      } else if (cid.substr(-5) === 'erc20') {
        symbol = cid.substr(0, cid.length - 5)
        chain = 'erc20'
      } else if (cid.substr(-5) === 'trc20') {
        symbol = cid.substr(0, cid.length - 5)
        chain = 'trc20'
      } else if (cid.substr(-7) === 'mainnet') {
        symbol = cid.substr(0, cid.length - 7)
        chain = 'mainnet'
      }

      return {
        cid,
        symbol,
        name: cryptoSymbols[symbol.toUpperCase()],
        chain: chain.toUpperCase()
      }
    })

    setCurrenciesDetails(availableCurrencies)
  }, [currencies])

  // getOrdersList for current walletAddress
  useEffect(() => {
    if (!walletAddress) return
    updateOrdersList(walletAddress, setOrdersList)
  }, [walletAddress, orderInfo])

  // getPaymentStatus for orders in OrdesList
  useEffect(() => {
    const run = async () => {
      const latestStatuses = []
      const incompleteOrders = []
      try {
        for await (const orderInfo of ordersList) {
          if (orderInfo.order.success) {
            const reqStatus = orderInfo.order && orderInfo.order.payment_status
            const ipnStatus = orderInfo.ipn && orderInfo.ipn.payment_status
            // const txHash = orderInfo.transfer && orderInfo.transfer.transactionHash
            const isIncomplete =
              (ipnStatus && incompleteStatuses.includes(ipnStatus)) ||
              incompleteStatuses.includes(reqStatus)
            if (isIncomplete) {
              const pid = orderInfo.order.payment.payment_id
              incompleteOrders.push(pid)
            } else {
              incompleteOrders.push(null)
            }

            if (ipnStatus) {
              latestStatuses.push(orderInfo.ipn)
            } else {
              const payment = orderInfo.order.payment
              latestStatuses.push(payment)
            }
          }
        }
        setOrdersListStatuses(latestStatuses)
        setIncompleteOrdersList(incompleteOrders)
      } catch (error) {
        console.log('update ordersListStatuses failed', error)
      }
    }
    run()
  }, [ordersList])

  // getPaymentStatus for incompleteOrdersList
  useEffect(() => {
    const latestStatuses = []
    let ordersUpdateTimeout
    const run = async () => {
      try {
        // console.log('incompleteOrdersList', incompleteOrdersList)

        const results = await mapLimit(
          incompleteOrdersList,
          async pid => {
            if (pid) {
              const res = await getPaymentStatus(pid)
              if (res.success && res.status) {
                // console.log('incomplete order', pid, res.status)
                return res.status
              } else {
                console.log('incomplete order failed', pid, res.success)
              }
            }
            return null
          },
          1
        )

        // console.log('incomplete results', results)
        let hasUpdates = false
        results.forEach((element, index) => {
          if (element) {
            if (
              ordersListStatuses[index].payment_status !==
              element.payment_status
            ) {
              hasUpdates = true
            }
            latestStatuses.push(element)
          } else {
            latestStatuses.push(ordersListStatuses[index])
          }
        })

        if (hasUpdates) {
          // console.log('recheck some statuses updated', results)
          setOrdersListStatuses(latestStatuses)
          ordersUpdateTimeout = setTimeout(() => {
            updateOrdersList(walletAddress, setOrdersList)
          }, 5000)
        }
      } catch (error) {
        console.log('recheck incompleteOrdersList failed', error)
      }
    }

    const interval = setInterval(() => {
      run()
    }, 10000)
    return () => {
      clearInterval(interval)
      if (ordersUpdateTimeout) {
        clearTimeout(ordersUpdateTimeout)
      }
    }
  }, [incompleteOrdersList, walletAddress])

  const dappProps = {
    setWalletAddress,
    hideButtons,
    setHideButtons,
    setAllowAutoHide,
    walletConnect
  }

  const swapProps = {
    setCloseOnOverlayClick,
    currenciesDetails,
    walletAddress,
    setOrderInfo,
    cryptoSymbols
  }

  let _OrdersHistory = null
  if (hideButtons && ordersList.length) {
    _OrdersHistory = (
      <div className='popup__presale presale-col'>
        <OrdersHistory
          ordersList={ordersList}
          ordersListStatuses={ordersListStatuses}
          currenciesDetails={currenciesDetails}
          orderInfo={orderInfo}
        />
      </div>
    )
  }

  return (
    <>
      <h2 className='popup__title'>BUY YETI Coin</h2>
      <div className='popup__content presale-wrapper'>
        <div className='popup__presale presale-col'>
          <DApp {...dappProps} />
          {hideButtons ? <Swap {...swapProps} /> : null}
        </div>
        {_OrdersHistory}
      </div>
    </>
  )
}

async function updateOrdersList (walletAddress, setOrdersList) {
  const res = await getOrdersList(walletAddress)
  console.log('getOrdersList', res.success, res.orders)
  if (res.success && res.orders) {
    setOrdersList(res.orders)
  }
}

export default BuyPresale
