function Specifications () {
  return (
    <>
      <div className='content-section section--hidden' data-page='2'>
        <h1 className='content-section__title'>COIN SPECIFICATIONS</h1>
        <p className='content-section__text'>
          <div className='content-section-p'>
            Yeti Coin is based on Binance Smart Chain blockchain (BSC) protocol.
          </div>
          <div className='content-section-p'>
            Yeti Сoin is tightly tied to all products in the Yeti Ecosystem. You
            will be able to pay for your VPN subscription with the Yeti Coin
            and, in the future, mine Yeti Coin in our Summer 2022 game.
          </div>
          <div className='content-section-p'>
            Yeti Coin holders will also be given the opportunity to use all of
            our products without any restrictions. You can see the roadmap and
            white paper below.
          </div>
          <div className='content-section-p'>
            Yeti is already listed on PancakeSwap. All the specifications and
            analytics are available on CoinMarketCap:
          </div>
        </p>
        <a
          className='content-section__button button button--third'
          href='https://coinmarketcap.com/currencies/yeti-coin/'
          target='_blank'
          rel='noreferrer'
        >
          COINMARKETCAP
        </a>
        {/* <!-- <button className="content-section__button button button--icon" id="button_video">
                  <img className="button__image" src="./resources/images/icons/play.svg" alt="Play icon" />
                  <span className="button__text">WATCH VIDEO</span>
                </button> --> */}
      </div>
    </>
  )
}

export default Specifications
