import React, { useEffect, useState } from 'react'

const untrackedURL = 'https://windows.yetivp.com/getid/windows'
const windowsDirectUrl = 'https://yetivp.com/dl/windows/direct/'

function Explore () {
  const [windowsLink, setWindowsLink] = useState(windowsDirectUrl)

  useEffect(() => {
    const getUntrackedLink = async () => {
      try {
        const response = await fetch(untrackedURL)
        const data = await response.json()
        if (data && data.clickid) {
          const uri = windowsLink.replace('direct', data.clickid)
          setWindowsLink(uri)
        }
      } catch (error) {
        console.log('dllink update failed', error)
      }
    }

    const urlParams = new URL(document.location).searchParams
    const clickid =
      urlParams.get('c') ||
      urlParams.get('clickid') ||
      urlParams.get('click_id')
    if (clickid && clickid !== '{clickid}') {
      setWindowsLink('https://getid.yetivp.com/click.php?lp=1')
    } else {
      getUntrackedLink()
    }
  }, [])

  return (
    <>
      <div className='content-section section--hidden' data-page='1'>
        <h1 className='content-section__title'>EXPLORE YETI ECOSYSTEM</h1>
        <p className='content-section__text'>
          Yeti Coin's goal is to make the internet as safe as possible for users
          around the world (free & safe internet). In 2020,
          <a target='_blank' href='https://yetivp.com' rel='noreferrer'>
            {' '}
            Yeti VPN{'  '}
          </a>
          utility was developed from scratch for IOS and Android devices. The
          app already has a large user base -{' '}
          <span className='boldblue'>
            300,000 downloads and 200,000 active users!
          </span>{' '}
          The application can be found on the App Store and Play Market. We have launched{' '}
          <a target='_blank' href='https://windows.yetivp.com' rel='noreferrer'>
            {' '}
            desktop version{'  '}
          </a>
          with implemented YETI Coin tokenomics.
        </p>

        <div className='store-links'>
          <a
            className='store-badge'
            target='_blank'
            rel='noreferrer'
            href='https://apps.apple.com/us/app/yeti-vpn/id1544800574'
          >
            <img
              src='/resources/images/icons/badge_ios.svg'
              alt=''
              height='62'
            />
          </a>
          <a
            className='store-badge'
            target='_blank'
            rel='noreferrer'
            href='https://play.google.com/store/apps/details?id=com.yetivp.vpn'
          >
            <img
              src='/resources/images/icons/google-play-badge.svg'
              alt=''
              height='60'
            />
          </a>
          <a
            className='store-badge'
            href={windowsLink}
            onClick={() =>
              setTimeout(
                () =>
                  (window.location.href =
                    'https://windows.yetivp.com/instructions.html'),
                500
              )
            }
          >
            <img
              src='/resources/images/icons/badge-windows-version.png'
              alt=''
              height='60'
            />
          </a>
        </div>

        {/* <!-- <button className="content-section__button button button--icon" id="button_video">
            <img className="button__image" src="./resources/images/icons/play.svg" alt="Play icon" />
            <span className="button__text">WATCH VIDEO</span>
          </button> --> */}
      </div>
    </>
  )
}

export default Explore
