import React from 'react'
import pages from '../pagesList'

function Paging (props) {
  const { currentPageIndex, setCurrentPageIndex } = props

  const pagingItems = pages.map((page, i) => (
    <div
      key={`paging-${i}`}
      onClick={() => {
        console.log(`paging-${i}`)
        setCurrentPageIndex(i)
      }}
      className={`paging__page ${
        i === currentPageIndex ? '' : 'section--hidden'
      }`}
    />
  ))

  return (
    <>
      <div className='fixed-content-paging'>{pagingItems}</div>
    </>
  )
}

export default Paging
