import React, { useState, useEffect } from 'react'

import Fixed from './fixed'
import Pages from './pages'
import Preloader from './Preloader'
import Menu from './fixed/Menu'
import ModalPopup from './popups/Modal'

function App () {
  const isLoaded = document.readyState === 'complete'
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalContent, setModalContent] = useState('')

  useEffect(() => {
    window.setCurrentPageIndex = setCurrentPageIndex
    if (window.uiCallback) window.uiCallback.onPagingClick(currentPageIndex)
    window.modalShow = modalShow
    // window.currentPageIndex = currentPageIndex
  }, [currentPageIndex, modalShow])

  const [showMenu, setShowMenu] = useState(false)

  return (
    <div
      className={`${showMenu ? 'menu-opened' : ''} ${
        isLoaded ? '' : 'page-not-loaded'
      }`}
    >
      <Preloader />
      <ModalPopup
        modalShow={modalShow}
        modalContent={modalContent}
        setModalShow={setModalShow}
      />
      <Fixed
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        setModalContent={setModalContent}
        setModalShow={setModalShow}
      />
      <main className='ui-wrapper'>
        <Menu
          setShowMenu={setShowMenu}
          setCurrentPageIndex={setCurrentPageIndex}
        />
        <div className='content'>
          <Pages
            setCurrentPageIndex={setCurrentPageIndex}
            setModalContent={setModalContent}
            setModalShow={setModalShow}
          />
        </div>
      </main>
    </div>
  )
}

export default App
