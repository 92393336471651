function Contact () {
  return (
    <>
      <div className='content-section section--hidden' data-page='7'>
        <h1 className='content-section__title'>CONTACT US</h1>
        <p className='content-section__text'>
          We’re here to help and answer any question you might have. We look
          forward to hearing from you. You can find us on social media below.
          <br />
          Follow us!
        </p>
        <div className='content-section__buttons-holder'>
          {/* <a
            className='content-section__button button button--icon'
            href='mailto:contact@yeticoin.tech'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='button__image'
              src='./resources/images/icons/email.svg'
              alt='Email icon'
            />
            <span className='button__text'>EMAIL US</span>
            <span className='button__invisible-email'>
              contact@yeticoin.tech
            </span>
          </a> */}

          {/* <a
            className='content-section__button button button--icon'
            href='https://twitter.com/YetiEcosystem'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='button__image'
              src='./resources/images/socials/twitter.svg'
              alt='Twitter icon'
            />
            <span className='button__text'>Twitter</span>
            <span className='button__invisible-email'>
              https://twitter.com/YetiEcosystem
            </span>
          </a> */}

          {/* <a
            className='content-section__button button button--icon'
            href='https://t.me/yetiecosystem'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='button__image'
              src='./resources/images/socials/telegram.svg'
              alt='Telegram icon'
            />
            <span className='button__text'>Telegram</span>
            <span className='button__invisible-email'>
              https://t.me/yetiecosystem
            </span>
          </a> */}

          {/* <a className="content-section__button button button--icon" href="tel:+0011223344">
              <img className="button__image" src="./resources/images/icons/phone.svg" alt="Phone icon" />
              <span className="button__text">CALL US</span>
            </a> */}
        </div>

        <div className='social-links-page'>
          <a
            className='social-links__anchor'
            href='https://www.facebook.com/yetiecosystem'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/facebook.svg'
              alt='Facebook logo'
            />
          </a>
          <a
            className='social-links__anchor'
            href='https://twitter.com/YetiEcosystem'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/twitter.svg'
              alt='Twitter logo'
            />
          </a>
          {/* <a
            className='social-links__anchor'
            href='https://www.instagram.com/yeti.ecosystem/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/instagram.svg'
              alt='Instagram logo'
            />
          </a> */}
          <a
            className='social-links__anchor'
            href='https://t.me/yetiecosystem'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/telegram.svg'
              alt='Telegram logo'
            />
          </a>
          {/* <a
            className='social-links__anchor'
            href='https://www.youtube.com/channel/UCoztSNQ7o8hIM-rLffFyMfw'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/youtube.svg'
              alt='Youtube logo'
            />
          </a> */}
          <a
            className='social-links__anchor'
            href='https://www.reddit.com/user/yeti_coin/?sort=hot'
            target='_blank'
            rel='noreferrer'
          >
            <img
              className='social-links__image'
              src='./resources/images/socials/reddit.svg'
              alt='Reddit logo'
            />
          </a>
        </div>
      </div>
    </>
  )
}

export default Contact
