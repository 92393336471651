function Roadmap (props) {
  const { setModalContent, setModalShow } = props
  return (
    <>
      <div className='content-section section--hidden' data-page='3'>
        <h1 className='content-section__title'>ROAD MAP</h1>
        <p className='content-section__text'>
          We are now actively developing new products aimed at keeping internet
          users as safe as possible from wiretaps, stolen passwords,
          surveillance and hackers. All of our products are a big ecosystem tied
          to Yeti Coin:
        </p>
        <div className='content-services'>
          <div className='content-services__service'>
            <img
              className='content-service__image'
              src='./resources/images/icons/marketing.svg'
              alt='Marketing icon'
            />
            <span className='content-service__text'>December 2021</span>
            <ul className='extended-services'>
              <li className='extended-services__service'>Listing🤫</li>
              <li className='extended-services__service'>Stay tuned</li>
            </ul>
          </div>
          <div className='content-services__service'>
            <img
              className='content-service__image'
              src='./resources/images/icons/consulting.svg'
              alt='Marketing icon'
            />
            <span className='content-service__text'>March 2022</span>
            <ul className='extended-services'>
              <li className='extended-services__service'>Yeti Messenger</li>
            </ul>
          </div>
          <div className='content-services__service'>
            <img
              className='content-service__image'
              src='./resources/images/icons/play.svg'
              alt='Marketing icon'
            />
            <span className='content-service__text'>May 2022</span>
            <ul className='extended-services'>
              <li className='extended-services__service'>Yeti Video Plugin</li>
            </ul>
          </div>
          <div className='content-services__service'>
            <img
              className='content-service__image'
              src='./resources/images/icons/analytics.svg'
              alt='Marketing icon'
            />
            <span className='content-service__text'>Spring 2022</span>
            <ul className='extended-services'>
              <li className='extended-services__service'>
                Trading analytics and order signals Telegram Channel
              </li>
            </ul>
          </div>
        </div>
        <button
          className='content-section__button button button--secondary'
          onClick={() => {
            setModalContent('roadmap')
            setModalShow(true)
          }}
        >
          OPEN DETAILED ROAD MAP
        </button>
      </div>
    </>
  )
}

export default Roadmap
