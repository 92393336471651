import React from 'react'

import Main from './Main'
import Explore from './Explore'
import Specifications from './Specifications'
import Roadmap from './Roadmap'
import Wallets from './Wallets'
import Trading from './Trading'
import Whitepaper from './Whitepaper'
import Contact from './Contact'

function Pages (props) {
  const { setCurrentPageIndex, setModalContent, setModalShow } = props

  return (
    <>
      <Main
        setCurrentPageIndex={setCurrentPageIndex}
        setModalContent={setModalContent}
        setModalShow={setModalShow}
      />
      <Explore />
      <Specifications />
      <Roadmap setModalContent={setModalContent} setModalShow={setModalShow} />
      <Wallets />
      <Trading setModalContent={setModalContent} setModalShow={setModalShow} />
      <Whitepaper />
      <Contact />
    </>
  )
}

export default Pages
