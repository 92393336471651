const pages = [
  { id: 'main', title: 'Main' },
  { id: 'ecosystem', title: 'Ecosystem' },
  { id: 'specs', title: 'Specifications' },
  { id: 'roadmap', title: 'Road Map' },
  { id: 'wallets', title: 'Wallets' },
  { id: 'trading', title: 'Trading' },
  { id: 'whitepaper', title: 'White paper' },
  { id: 'contact', title: 'Сontact us' }
]

export default pages
