import Header from './Header'
import Footer from './Footer'
import Paging from './Paging'

function Fixed (props) {
  const {
    showMenu,
    setShowMenu,
    currentPageIndex,
    setCurrentPageIndex,
    setModalContent,
    setModalShow
  } = props
  return (
    <>
      <Header
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        setModalContent={setModalContent}
        setModalShow={setModalShow}
      />
      <Paging
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
      />
      <Footer />
    </>
  )
}

export default Fixed
