import pages from '../pagesList'

function Main (props) {
  const { setCurrentPageIndex, setModalContent, setModalShow } = props
  return (
    <>
      <div className='content-section' data-page='0'>
        <div className='configuration__letters configuration__letters--hidden'>
          <span className='configuration__letters-text'>YETI Coin</span>
        </div>
        <div className='page0-wb'>
          <h1 className='content-section__title page0'>
            Making the world safer for all
          </h1>
          <div className='page-buttons page0'>
            <BuyButton
              setModalContent={setModalContent}
              setModalShow={setModalShow}
            />
            <ExploreButton setCurrentPageIndex={setCurrentPageIndex} />
          </div>
        </div>
        <ScrollLetters />
      </div>
    </>
  )
}

function BuyButton (props) {
  const { setModalContent, setModalShow } = props
  return (
    <button
      className='button button--third'
      id='button_buy_page0'
      onClick={() => {
        setModalContent('buy')
        setModalShow(true)
      }}
    >
      BUY YETI
    </button>
  )
}

function ExploreButton (props) {
  const { setCurrentPageIndex } = props
  return (
    <button
      className='button button--third'
      onClick={() => {
        const index = pages.findIndex(page => page.id === 'ecosystem')
        setCurrentPageIndex(index)
      }}
    >
      EXPLORE
    </button>
  )
}

function ScrollLetters (props) {
  return (
    <div className='scroll-letters'>
      <span className='scroll-letters__letter' title='S'>
        S
      </span>
      <span className='scroll-letters__letter' title='C'>
        C
      </span>
      <span className='scroll-letters__letter' title='R'>
        R
      </span>
      <span className='scroll-letters__letter' title='O'>
        O
      </span>
      <span className='scroll-letters__letter' title='L'>
        L
      </span>
      <span className='scroll-letters__letter' title='L'>
        L
      </span>
    </div>
  )
}

export default Main
