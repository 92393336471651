import pages from '../pagesList'

function Menu (props) {
  const { setShowMenu, setCurrentPageIndex } = props

  const menuItems = pages.map((page, i) => {
    const { id, title } = page
    if (id === 'main') return null

    return (
      <MenuItem
        key={`popup-menu-${i}`}
        pageIndex={i}
        text={title}
        onClick={() => {
          console.log(`popup-menu-${i}`)
          setShowMenu(false)
          setCurrentPageIndex(i)
        }}
      />
    )
  })

  return (
    <div className='menu'>
      <ul className='menu-list'>{menuItems}</ul>
    </div>
  )
}

function MenuItem (props) {
  const { text, pageIndex, onClick } = props
  return (
    <li className='menu-list__item' onClick={onClick} data-page={pageIndex}>
      {text}
    </li>
  )
}

export default Menu
